import React, { useEffect, useState, useCallback } from 'react';
import { useSelector, useDispatch } from "react-redux";
import { getSprintData, selectSprintDatesData, insertSprintData, updateSprintData, getLoadingState } from '../../redux/slices/sprintDatesSlice';
import { OrcaTable } from '../../Components/common/OrcaTable';
import store from '../../redux/store';
import { LdsModal, useLdsModal, LdsButton, LdsTextField, LdsSelect, LdsIcon } from "@elilillyco/ux-lds-react";

export const SprintDateAdmin = () => {
  const dispatch = useDispatch();
  const data = useSelector(selectSprintDatesData);
  const loading = useSelector(getLoadingState);

  const { isModalOpen, setIsModalOpen } = useLdsModal();
  const [modalHeading, setModalHeading] = useState('Update Sprint Dates');
  const [formData, setFormData] = useState({ sprint: '', mid_date: '', start_date: '', end_date: '' });

  useEffect(() => {
    dispatch(getSprintData());
  }, [dispatch]);

  const actionButtons = [
    {
      iconName: 'EyeFill',
      iconLabel: 'View Sprint Details',
      onClick: (row) => {
        let newrow = { ...row };
        setFormData(newrow);
        setModalHeading('View Sprint');
        setIsModalOpen(true);
      },
      isVisible: (userRole, row) => true,
    },
    {
      iconName: 'PencilSimpleLineFill',
      iconLabel: 'Edit Sprint Information',
      onClick: (row) => {
        let newrow = { ...row };
        setFormData(newrow);
        setModalHeading('Update Sprint');
        setIsModalOpen(true);
      },
      isVisible: (userRole, row) => userRole === 'Admin',
    },
  ];

  // New action button for inserting a record
  const insertButton = {
    itemText: 'Create New Sprint',
    onClick: () => {
      setFormData({ sprint: '', mid_date: '', start_date: '', end_date: '' }); // Clear form data for new record
      setModalHeading('Create New Sprint');
      setIsModalOpen(true);
    },
    isVisible: (userRole, row) => userRole === 'Admin',
  };

  const handleInputChange = useCallback((e) => {
    const { name, value } = e.target;
    setFormData(prevData => ({
      ...prevData,
      [name]: value
    }));
  }, []);

  const handleInsertSubmit = async () => {
    await dispatch(insertSprintData(formData));
    setIsModalOpen(false);
    dispatch(getSprintData());
  };

  const handleUpdateSubmit = () => {
    dispatch(updateSprintData(formData));
    setIsModalOpen(false);
  };



  const UpdateSprintDatesData = ({ formData }) => {
    return (
      <>
        <div className='box'>
          <LdsTextField label="Sprint" value={formData.sprint} disabled={true} style={{ color: 'inherit', marginBottom: '10px', marginTop: '10px' }} />
          <LdsTextField label="Implementation Start Date" value={formData.start_date} name="start_date" style={{ color: 'inherit', marginBottom: '10px', marginTop: '10px' }} />
          <LdsTextField label="Mid-Sprint Release Date" value={formData.mid_date} name="mid_date" style={{ color: 'inherit', marginBottom: '10px', marginTop: '10px' }} />
          <LdsTextField label="End-Sprint Release Date" value={formData.end_date} name="end_date" style={{ color: 'inherit', marginBottom: '10px', marginTop: '10px' }} />
        </div>
        {<LdsButton id="yesButton" value="delete" label="Delete" name="submit" style={{ marginBottom: '10px', marginTop: '20px' }} onClick={() => updateSubmit(formData)} >Submit</LdsButton>}
        {<LdsButton id="noButton" value="cancel" label="Cancel" name="submit" style={{ marginBottom: '10px', marginTop: '20px', float: 'right' }} onClick={() => setIsModalOpen(false)} >Cancel</LdsButton>}
      </>
    );
  }

  const updateSubmit = (formData) => {
    const newVal = document.getElementsByName('start_date')[0].value;
    formData.start_date = newVal;
    store.dispatch(updateSprintData(formData));
    setIsModalOpen(false);
  };

  const fieldsValues = [
    { value: 'sprint', label: 'Sprint' },
    { value: 'start_date', label: 'Implementation Start Date' },
    { value: 'mid_date', label: 'Mid-Sprint Release Date' },
    { value: 'end_date', label: 'End-Sprint Release Date' }
  ];


  return (
    <>
      {/* Plus fill icon for inserting a new record */}
      <div style={{ textAlign: 'right', marginBottom: '10px' }}>
        <a aria-label='Create Request' href='#modal' tabIndex={0} onClick={insertButton.onClick}> <span style={{ width: '310.31px', height: '21px', display: 'inline-block', fontSize: '18px', fontFamily: 'Roboto' }}>Create New Sprint</span>
          <LdsIcon
            label="Insert"
            name="PlusCircleFill"
            className="inline mt-2"
            onClick={insertButton.onClick}
          />
        </a>
      </div>
      <LdsModal
        modalId="ViewSprint"
        open={isModalOpen}
        setModalOpen={setIsModalOpen}
        heading={modalHeading || "View Sprint details"}
        persistent
      > 
        <LdsButton
          clearDefaultClasses
          className="lds-button-clear-style lds-modal-close-btn focus icon"
          onClick={() => setIsModalOpen(false)}
          icon="X"
          iconOnly
        />
        {modalHeading === 'View Sprint' &&
          <>
            <div className='controls' style={{ display: 'grid', gridTemplateColumns: '500px 500px', justifyContent: 'space-between', alignItems: 'center' }}>
              {fieldsValues.map((field, index) => (
                <LdsTextField key={index} label={field.label} value={formData[field.value]} disabled={true} style={{ color: 'inherit', marginBottom: '10px', marginTop: '10px' }} />
              ))}
            </div>
          </>
        }
        {modalHeading === 'Create New Sprint' &&
          <>
            <div className='box'>
              <LdsTextField label="Sprint" name="sprint" value={formData.sprint} onChange={handleInputChange} style={{ color: 'inherit', marginBottom: '10px', marginTop: '10px' }} />
              <LdsTextField label="Implementation Start Date" name="start_date" value={formData.start_date} onChange={handleInputChange} style={{ color: 'inherit', marginBottom: '10px', marginTop: '10px' }} />
              <LdsTextField label="Mid-Sprint Release Date" name="mid_date" value={formData.mid_date} onChange={handleInputChange} style={{ color: 'inherit', marginBottom: '10px', marginTop: '10px' }} />
              <LdsTextField label="End-Sprint Release Date" name="end_date" value={formData.end_date} onChange={handleInputChange} style={{ color: 'inherit', marginBottom: '10px', marginTop: '10px' }} />
            </div>
            <LdsButton id="yesButton" value="insert" label="Insert" name="submit" style={{ marginBottom: '10px', marginTop: '20px' }} onClick={handleInsertSubmit} type='button'>Insert</LdsButton>
            <LdsButton id="noButton" value="cancel" label="Cancel" name="submit" style={{ marginBottom: '10px', marginTop: '20px', float: 'right' }} onClick={() => setIsModalOpen(false)} type='button'>Cancel</LdsButton>
          </>
        }
        {modalHeading === 'Update Sprint' &&
          <>
            <div className='box'>
              <LdsTextField label="Sprint" name="sprint" value={formData.sprint} disabled={true} onChange={handleInputChange} style={{ color: 'inherit', marginBottom: '10px', marginTop: '10px' }} />
              <LdsTextField label="Implementation Start Date" name="start_date" value={formData.start_date} onChange={handleInputChange} style={{ color: 'inherit', marginBottom: '10px', marginTop: '10px' }} />
              <LdsTextField label="Mid-Sprint Release Date" name="mid_date" value={formData.mid_date} onChange={handleInputChange} style={{ color: 'inherit', marginBottom: '10px', marginTop: '10px' }} />
              <LdsTextField label="End-Sprint Release Date" name="end_date" value={formData.end_date} onChange={handleInputChange} style={{ color: 'inherit', marginBottom: '10px', marginTop: '10px' }} />
            </div>
            <LdsButton id="yesButton" value="update" label="Update" name="submit" style={{ marginBottom: '10px', marginTop: '20px' }} onClick={handleUpdateSubmit} type='button'>Submit</LdsButton>
            <LdsButton id="noButton" value="cancel" label="Cancel" name="submit" style={{ marginBottom: '10px', marginTop: '20px', float: 'right' }} onClick={() => setIsModalOpen(false)} type='button'>Cancel</LdsButton>
          </>
        }
      </LdsModal>
      <OrcaTable loading={loading} actionButtons={actionButtons} rows={data} showFilters title='Sprint Date Management' filterColumns={['sprint']} displayKey={{ 'sprint': { 'display': true }, 'start_date': { 'display': true }, 'mid_date': { 'display': true }, 'end_date': { 'display': true } }} />
    </>
  )
}
