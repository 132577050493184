import { combineReducers } from "redux";
import { userSlice } from "./slices/userSlice";
import { requestsSlice } from "./slices/requestsSlice";
import { serviceCatalogSlice } from "./slices/serviceCatalogSlice";
import { cslSlice } from "./slices/cslSlice";
import { sprintDatesSlice } from "./slices/sprintDatesSlice";
import mlSlice from "./slices/mlSlice"
import brndindSlice from "./slices/brndindSlice"

const rootReducer = combineReducers({
  serviceCatalog: serviceCatalogSlice.reducer,
  user: userSlice.reducer,
  requests: requestsSlice.reducer,
  csl: cslSlice.reducer,
  sprintDates: sprintDatesSlice.reducer,
  ml: mlSlice.reducer,
  brndindSlice: brndindSlice.reducer
});

export default rootReducer;
