import React, { forwardRef, useEffect, useLayoutEffect, useRef } from 'react';
import Quill from 'quill';
const Delta = Quill.import('delta');
import "quill/dist/quill.snow.css";

const returnPlainTextOrDelta = (value) => {
    try {
        const parsedValue = JSON.parse(value);
        if (parsedValue && parsedValue.ops) {
            return parsedValue;
        }
    } catch (error) {
        return value;
    }

    return value;
};

// Editor is an uncontrolled React component
const QuillEditor = forwardRef(
  ({ readOnly, defaultValue, onTextChange, onSelectionChange, placeholder, value }, ref) => {
    const placeholderText = placeholder || 'Enter text here...';
    const containerRef = useRef(null);
    const defaultValueRef = useRef(value);
    const onTextChangeRef = useRef(onTextChange);
    const onSelectionChangeRef = useRef(onSelectionChange);
    const valueRef = useRef(value);

    useLayoutEffect(() => {
      onTextChangeRef.current = onTextChange;
      onSelectionChangeRef.current = onSelectionChange;
    });

    useEffect(() => {
      ref.current?.enable(!readOnly)
    }, [ref, readOnly]);

    useEffect(() => {
      if (value != null) {
        const valRef = returnPlainTextOrDelta(value);
        if (typeof valRef != "string" && valRef.ops) {
            ref.current?.setContents(valRef);
        } else {
            ref.current?.setText(valRef);
        }
      }
    });

    useEffect(() => {
      const container = containerRef.current;
      const editorContainer = container.appendChild(
        container.ownerDocument.createElement('div'),
      );
      
      const quill = new Quill(editorContainer, {
        theme: 'snow',
        modules: {
            toolbar: [
                ['bold', 'italic', 'underline', 'strike'],
                [{ 'list': 'ordered'}, { 'list': 'bullet' }],
                [{'color': ['black', 'red','blue','green']}], ['clean']
            ],
        },
        placeholder: placeholderText,
        readOnly: readOnly,
      });

      ref.current = quill;

      quill.on(Quill.events.TEXT_CHANGE, (...args) => {
        onTextChangeRef.current?.(...args);
        valueRef.current = quill.getContents();
      });

      quill.on(Quill.events.SELECTION_CHANGE, (...args) => {
        onSelectionChangeRef.current?.(...args);
      });

      return () => {
        ref.current = null;
        container.innerHTML = '';
      };
    }, [ref]);

    return <div ref={containerRef}></div>;
  },
);

QuillEditor.displayName = 'Editor';

export default QuillEditor;