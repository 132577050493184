import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from "react-redux";
import { getMlData, selectMlData, updateMlData, getLoadingState } from '../../redux/slices/mlSlice';
import { OrcaTable } from '../../Components/common/OrcaTable';
import { LdsModal, useLdsModal, LdsButton, LdsTextField } from "@elilillyco/ux-lds-react";
import { Formik, Form, Field } from "formik";
import { OrcaSelect, OrcaEnhancementSelect, OrcaGranularSelect, OrcaIndicationSelect, OrcaMetricSelect,OrcaBrandIndSelect,OrcaSprintSelect} from "../../Components/common/OrcaSelect";
import {OrcaScrollingCB,OrcaBrandSelect} from "../../Components/common/OrcaScrollingCB";
import { QuillTextArea, OrcaField, OrcaEffortField, OrcaTextArea, OrcaPriorityScore } from "../../Components/common/OrcaTextInput";
import { OrcaFormOptions } from "../../Components/common/OrcaFormOptions";

export const MetricsLibraryAdmin = () => {
  const data = useSelector(selectMlData);
  const loading = useSelector(getLoadingState);
  const dispatch = useDispatch();

  const { isModalOpen, setIsModalOpen } = useLdsModal();
  const [modalHeading, setModalHeading] = useState('Update Metrics Library Admin');
  const [formData, setFormData] = useState({});
  const [isConfirmationOpen, setIsConfirmationOpen] = useState(false);
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);

  useEffect(() => {
    dispatch(getMlData());
  }, [dispatch]);

  const actionButtons = [
    {
      iconName: 'PencilSimpleLineFill',
      iconLabel: 'Edit Metrics Library',
      onClick: (row) => {
        setFormData({ ...row });
        setModalHeading('Update Metrics Library');
        setIsEditModalOpen(true);
      },
      isVisible: (userRole, row) => userRole === 'Admin',
    }
  ];

  const handleUpdateConfirmation = () => {
    dispatch(updateMlData(formData));
    setIsModalOpen(false);
    setIsConfirmationOpen(false);
  };

  const UpdatemetricsLibForm = ({ formData, editable=false }) => (
    <Formik
      initialValues={formData}
      onSubmit={(values, { setSubmitting }) => {
        if (!values['action'] || values['action']!='save') {
            setSubmitting(false);
            return
        }
        setSubmitting(true);
        setFormData(values);
        setIsConfirmationOpen(true);
        setIsEditModalOpen(false);
        setSubmitting(false);
      }}
      enableReinitialize
    >
      {({ isSubmitting, values, handleChange, setFieldValue }) => (
        <Form>
          <div className='box'>
            <OrcaSelect name="insight_graph_type" disabled={!editable}/>
            <OrcaMetricSelect name="insight_graph_metric_key" disabled={!editable}  />
            <OrcaField name="insight_graph_metric_name" disabled={!editable} />
            <OrcaSelect name="insight_graph_display_section" disabled={!editable} />
            <OrcaSelect name="insight_graph_display_format" disabled={!editable}/>
            <OrcaTextArea name="insight_graph_metric_to_display" disabled={!editable} />
            <OrcaField name="insight_graph_market_basket_class_name" disabled={!editable} />
            <OrcaField name="insight_graph_entities_to_display" disabled={!editable} />
            <OrcaField name="insight_graph_source_owner" disabled={!editable} />
            <OrcaField name="insight_graph_source_filename" disabled={!editable} />
            <OrcaField name="insight_graph_data_source" disabled={!editable} />
            <OrcaBrandIndSelect name="insight_graph_brand" disabled={!editable}/>
            {/*<OrcaIndicationSelect name="insight_graph_indication" formlink="insight_graph_brand" map={OrcaFormOptions['brandIndicationMap']} disabled={!editable} />*/}
            <OrcaField name="insight_graph_graph_title" disabled={!editable} />
            <OrcaField name="insight_graph_time_based" disabled={!editable}/>
            <OrcaField name="insight_graph_x_label" disabled={!editable} />
            <OrcaField name="insight_graph_y_label" disabled={!editable} />
            <OrcaField name="insight_graph_grain" disabled={!editable} />
            <OrcaField name="insight_graph_look_back" disabled={!editable} />
            <OrcaScrollingCB name="insight_graph_applicable_for_sleeve" disabled={!editable} />
          </div>
          <LdsButton
            id="yesButton"
            value="update"
            label="Update"
            name="submit"
            style={{ marginBottom: '10px', marginTop: '20px' }}
            type="submit"
            onClick={() => setFieldValue("action", "save")}
            disabled={isSubmitting}
          >
            Submit
          </LdsButton>
          <LdsButton
            id="noButton"
            value="cancel"
            label="Cancel"
            name="submit"
            style={{ marginBottom: '10px', marginTop: '20px', float: 'right' }}
            onClick={() => setIsEditModalOpen(false)}
            disabled={isSubmitting}
          >
            Cancel
          </LdsButton>
        </Form>
      )}
    </Formik>
  );

  return (
    <>
      <LdsModal
        modalId="viewMetricModal"
        open={isModalOpen}
        setModalOpen={setIsModalOpen}
        heading={modalHeading || "View Metric Details"}
      >
        <div className='controls' style={{ display: 'grid', gridTemplateColumns: '500px 500px', justifyContent: 'space-between', alignItems: 'center' }}>
          {modalHeading === 'View Metrics Library' && <UpdatemetricsLibForm formData={formData} />}
        </div>
      </LdsModal>
      <LdsModal
        modalId="editmetricsAdminModal"
        open={isEditModalOpen}
        setModalOpen={setIsEditModalOpen}
        heading={modalHeading || "Update Metrics Library"}
      >
        {modalHeading === 'Update Metrics Library' && <UpdatemetricsLibForm formData={formData} editable />}
      </LdsModal>
      <LdsModal
        modalId="updateConfirmationModal"
        open={isConfirmationOpen}
        setModalOpen={setIsConfirmationOpen}
        heading="Confirmation"
      >
        <p>Are you sure you want to update this item?</p>
        <LdsButton
          id="yesButton"
          value="update"
          label="update"
          name="submit"
          style={{ marginBottom: '10px', marginTop: '20px' }}
          onClick={handleUpdateConfirmation}
        >
          Yes
        </LdsButton>
        <LdsButton
          id="noButton"
          value="cancel"
          label="No"
          name="submit"
          style={{ marginBottom: '10px', marginTop: '20px', float: 'right' }}
          onClick={() => setIsConfirmationOpen(false)}
        >
          No
        </LdsButton>
      </LdsModal>
      <OrcaTable 
        loading={loading} 
        showFilters 
        rows={data} 
        actionButtons={actionButtons} 
        title='Visual Metrics Library'
        displayKey={{
            'insight_graph_type': { 'display': true }, 
            'insight_graph_metric_key': { 'display': true },
            'insight_graph_display_section': { 'display': true }, 
            'insight_graph_display_format': { 'display': true },
            'insight_graph_metric_to_display': { 'display': true }, 
            'insight_graph_metric_name': { 'display': true },
            'insight_graph_market_basket_class_name': { 'display': true }, 
            'insight_graph_entities_to_display': { 'display': true },
            'insight_graph_brand_indication': { 'display': true }, 
            'insight_graph_source_owner': { 'display': true },
            'insight_graph_source_filename': { 'display': true }, 
            'insight_graph_data_source': { 'display': true },
            'insight_graph_graph_title': { 'display': true }, 
            'insight_graph_time_based': { 'display': true },
            'insight_graph_x_label': { 'display': true }, 
            'insight_graph_y_label': { 'display': true },
            'insight_graph_grain': { 'display': true }, 
            'insight_graph_look_back': { 'display': true },
            'insight_graph_applicable_for_sleeve': { 'display': true }
        }}
        filterColumns={[
            'insight_graph_type', 
            'insight_graph_display_section', 
            'insight_graph_display_format',
            'insight_graph_metric_to_display', 
            'insight_graph_market_basket_class_name', 
            'insight_graph_entities_to_display',
            'insight_graph_brand_indication', 
            'insight_graph_graph_title', 
            'insight_graph_time_based', 
            'insight_graph_x_label',
            'insight_graph_y_label', 
            'insight_graph_grain', 
            'insight_graph_look_back', 
            'insight_graph_data_source',
            'insight_graph_metric_title', 
            'insight_graph_source_owner', 
            'insight_graph_source_filename', 
            'insight_graph_metric_key',
            'insight_graph_metric_name', 
            'insight_graph_applicable_for_sleeve'
        ]}
    />
    </>
  );
};
