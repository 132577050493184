import React, { useEffect, useState, useCallback } from 'react';
import { useSelector, useDispatch } from "react-redux";
import { getbrandindicationData, selectbrandindicationData, insertbrandindicationData, updatebrandindicationData, getLoadingState } from '../../redux/slices/brndindSlice';
import { OrcaTable } from '../../Components/common/OrcaTable';
import { LdsModal, useLdsModal, LdsButton, LdsTextField, LdsSelect, LdsIcon } from "@elilillyco/ux-lds-react";

export const BrandIndicationAdmin = () => {
  const dispatch = useDispatch();
  const data = useSelector(selectbrandindicationData);
  const loading = useSelector(getLoadingState);

  const { isModalOpen, setIsModalOpen } = useLdsModal();
  const [modalHeading, setModalHeading] = useState('Update Brand Indication mapping');
  const [formData, setFormData] = useState({ brand: '', indication: '' });

  useEffect(() => {
    dispatch(getbrandindicationData());
  }, [dispatch]);

  const actionButtons = [
    {
      iconName: 'EyeFill',
      iconLabel: 'View Brand Indication Mapping Details',
      onClick: (row) => {
        let newrow = { ...row };
        setFormData(newrow);
        setModalHeading('View Brand Indication Mapping');
        setIsModalOpen(true);
      },
      isVisible: (userRole, row) => true,
    },
    {
      iconName: 'PencilSimpleLineFill',
      iconLabel: 'Edit Indication',
      onClick: (row) => {
        let newrow = { ...row };
        setFormData(newrow);
        setModalHeading('Update Brand/Indication Mapping');
        setIsModalOpen(true);
      },
      isVisible: (userRole, row) => userRole === 'Admin',
    },
  ];

  // New action button for inserting a record
  const insertButton = {
    itemText: 'Create new brand indication mapping',
    onClick: () => {
      setFormData({ brand: '', indication: '' }); // Clear form data for new record
      setModalHeading('Insert new brand indication mapping');
      setIsModalOpen(true);
    },
    isVisible: (userRole, row) => userRole === 'Admin',
  };

  const handleInputChange = useCallback((e) => {
    const { name, value } = e.target;
    setFormData(prevData => ({
      ...prevData,
      [name]: value
    }));
  }, []);


  const handleInsertSubmit = async () => {
    await dispatch(insertbrandindicationData(formData));
    setIsModalOpen(false);
    dispatch(getbrandindicationData()); // Fetch updated data immediately after insert
  };
  const handleUpdateSubmit = () => {
    dispatch(updatebrandindicationData(formData));
    setIsModalOpen(false);
  };

  const fieldsValues = [
    { value: 'brand', label: 'Brand' },
    { value: 'indication', label: 'Indication' }
  ];

  return (
    <>
      {/* Plus fill icon for inserting a new record */}
      <div style={{ textAlign: 'right', marginBottom: '10px' }}>
        <a aria-label='Create Request' href='#modal' tabIndex={0} onClick={insertButton.onClick}> <span style={{ width: '310.31px', height: '21px', display: 'inline-block', fontSize: '18px', fontFamily: 'Roboto' }}>Create new brand indication mapping</span>
          <LdsIcon
            label="Insert"
            name="PlusCircleFill"
            className="inline mt-2"
            onClick={insertButton.onClick}
          />
        </a>
      </div>
      <LdsModal
        modalId="ViewBrandIndicationMapping"
        open={isModalOpen}
        setModalOpen={setIsModalOpen}
        heading={modalHeading || "View Brand Indication Mapping Details"}
        persistent
      >
        <LdsButton
          clearDefaultClasses
          className="lds-button-clear-style lds-modal-close-btn focus icon"
          onClick={() => setIsModalOpen(false)}
          icon="X"
          iconOnly
        />
        {modalHeading === 'View Brand Indication Mapping' &&
          <>
            <div className='controls' style={{ display: 'grid', gridTemplateColumns: '500px 500px', justifyContent: 'space-between', alignItems: 'center' }}>
              {fieldsValues.map((field, index) => (
                <LdsTextField key={index} label={field.label} value={formData[field.value]} disabled={true} style={{ color: 'inherit', marginBottom: '10px', marginTop: '10px' }} />
              ))}
            </div>
          </>
        }
        {modalHeading === 'Insert new brand indication mapping' &&
          <>
            <div className='box'>
              <LdsTextField label="Brand" name="brand" value={formData.brand} onChange={handleInputChange} style={{ color: 'inherit', marginBottom: '10px', marginTop: '10px' }} />
              <LdsTextField label="Indication" name="indication" value={formData.indication} onChange={handleInputChange} style={{ color: 'inherit', marginBottom: '10px', marginTop: '10px' }} />
            </div>
            <LdsButton id="yesButton" value="insert" label="Insert" name="submit" style={{ marginBottom: '10px', marginTop: '20px' }} onClick={handleInsertSubmit} type='button'>Insert</LdsButton>
            <LdsButton id="noButton" value="cancel" label="Cancel" name="submit" style={{ marginBottom: '10px', marginTop: '20px', float: 'right' }} onClick={() => setIsModalOpen(false)} type='button'>Cancel</LdsButton>
          </>
        }
        {modalHeading === 'Update Brand/Indication Mapping' &&
          <>
            <div className='box'>
              <LdsTextField label="Brand" name="brand" value={formData.brand} onChange={handleInputChange} style={{ color: 'inherit', marginBottom: '10px', marginTop: '10px' }} />
              <LdsTextField label="Indication" name="indication" value={formData.indication} onChange={handleInputChange} style={{ color: 'inherit', marginBottom: '10px', marginTop: '10px' }} />
            </div>
            <LdsButton id="yesButton" value="update" label="Update" name="submit" style={{ marginBottom: '10px', marginTop: '20px' }} onClick={handleUpdateSubmit} type='button'>Submit</LdsButton>
            <LdsButton id="noButton" value="cancel" label="Cancel" name="submit" style={{ marginBottom: '10px', marginTop: '20px', float: 'right' }} onClick={() => setIsModalOpen(false)} type='button'>Cancel</LdsButton>
          </>
        }
      </LdsModal>
      <OrcaTable loading={loading} actionButtons={actionButtons} rows={data} showFilters title='Brand Indication Mapping Management' filterColumns={['brand', 'indication']} displayKey={{ 'brand': { 'display': true }, 'indication': { 'display': true } }} />
    </>
  )
}
