import { createSlice,createAsyncThunk } from '@reduxjs/toolkit';
import { OrcaAPI } from '../../ApiServices/OrcaAPI';

const initialState = {
  sprintDates: [],
  };

export const sprintDatesSlice = createSlice({
  name: 'sprintDates',
  initialState,
  reducers: {
    setSprintDatesData: (state, action) => {
      state.sprintDates = action.payload
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getSprintData.pending, (state) => {
        state.sprintDates = [];
        state.loading = true;
      })
      .addCase(getSprintData.fulfilled, (state, action) => {
        //console.log("Fulfilled Payload:", action.payload);
        state.loading = false;
        let sprintDates = action.payload;
        state.sprintDates = sprintDates.map(row => {
          let newrow = {
            ...row,
          }
          return newrow;
        });
        state.sprintDates = state.sprintDates.sort((a, b) => { return Number(b.id) - Number(a.id)})
        //console.log(state.sprintDates)
      })
      .addCase(getSprintData.rejected, (state) => {
        state.loading = false;
        state.sprintDates = [];
      }).addCase(insertSprintData.pending, (state) => {
        state.loading = true;
      }).addCase(insertSprintData.fulfilled, (state, action) => {
        state.loading = false;
        state.sprintDates = state.sprintDates.filter((row) => { return row.id !== action.payload.id; });
      }).addCase(insertSprintData.rejected, (state) => {
        state.loading = false;
      }).addCase(updateSprintData.pending, (state) => {
        state.loading = true;
      }).addCase(updateSprintData.fulfilled, (state, action) => {
        state.loading = false;
        state.sprintDates = state.sprintDates.map((row) => {
          if (row.id === action.payload.id) {
            return action.payload;
          }
          return row;
        });
      }).addCase(updateSprintData.rejected, (state) => {
        state.loading = false;
      });
  }
});

export const getSprintData = createAsyncThunk('requests/getSprintData', async () => {
  const response = await OrcaAPI({ uri: "/sprint_dates", method: "GET" });
  return response.data;
});

export const insertSprintData = createAsyncThunk('requests/insertSprintData', async (data) => {
  data = { ...data, action: 'insert' };
  const response = await OrcaAPI({ uri: "/sprint_dates", method: "PUT", body: data });
  if (response.statusCode === 200) {
    return data;
  }
  return null;
});

export const updateSprintData = createAsyncThunk('requests/updateSprintData', async (data) => {
  data = { ...data, action: 'save'}
  const response = await OrcaAPI({ uri: "/sprint_dates", method: "POST", body: data });
  if (response.statusCode === 200) {
    return data;
  }
  return null;
});

export const { setSprintDatesData } = sprintDatesSlice.actions;
export const selectSprintDatesData = (state) => state.sprintDates.sprintDates;
console.log("selectSprintDatesData",selectSprintDatesData)
export const getLoadingState = (state) => state.sprintDates.loading;

export default sprintDatesSlice.reducer;
