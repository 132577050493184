const salesForceOptions = [
    { name: "sales_force", value: 'USV4', label: 'USV4 - DERM 1' },
    { name: "sales_force", value: 'USV5', label: 'USV5 - DERM 2' },
    { name: "sales_force", value: 'USV6', label: 'USV6 - RHEUM 1' },
    { name: "sales_force", value: 'USV7', label: 'USV7 - RHEUM 2' },
    { name: "sales_force", value: 'USV8', label: 'USV8 - DERM RHEUM HYBRID' },
    { name: "sales_force", value: 'US1M', label: 'US1M - GI' },
    { name: "sales_force", value: 'USD1', label: 'USD1 - DIAB SPEC1 HERITAGE' },
    { name: "sales_force", value: 'USD2', label: 'USD2 - CCI SPEC' },
    { name: "sales_force", value: 'USD3', label: 'USD3 - DIAB SPEC3 SYNERGY' },
    { name: "sales_force", value: 'USD4', label: 'USD4 - DIAB SPEC1 HERITAGE HYBRID' },
    { name: "sales_force", value: 'USD5', label: 'USD5 - CCI SPEC' },
    { name: "sales_force", value: 'USD6', label: 'USD6 - DIAB SPEC3 SYNERGY HYBRID' },
    { name: "sales_force", value: 'USW6', label: 'USW6 - PC 1' },
    { name: "sales_force", value: 'USW7', label: 'USW7 - PC 2' },
    { name: "sales_force", value: 'US3M', label: 'US3M - TRIUMPH OBESITY SPEC' },
    { name: "sales_force", value: 'USR5', label: 'USR5 - ONCOLOGY BREAST' },
    { name: "sales_force", value: 'USR6', label: 'USR6 - ONCOLOGY HEM' },
    { name: "sales_force", value: 'USR7', label: 'USR7 - ONCOLOGY PORTFOLIO' },
]
const brandIndicationOptions = [
    { value: "", label: "Select Brand"},
    { value: "All - All", label: "All - ALL" },
    { value: "Not Applicable - NA", label: "Not Applicable - NA" },
    { value: "Olumiant - AA", label: "Olumiant - AA" },
    { value: "Taltz - PsO", label: "Taltz - PsO" },
    { value: "Taltz - PsA", label: "Taltz - PsA" },
    { value: "Omvoh - UC", label: "Omvoh - UC" },
    { value: "Lebrikuzimab - AD", label: "Lebrikuzimab - AD" },
    { value: "Jaypirca - CLL", label: "Jaypirca - CLL" },
    { value: "Jaypirca - MCL", label: "Jaypirca - MCL" },
    { value: "Jaypirca - Anchor", label: "Jaypirca - Anchor" },
    { value: "Mounjaro - T2D", label: "Mounjaro - T2D" },
    { value: "Zepbound - CWM", label: "Zepbound - CWM" },
    { value: "Jardiance - T2D", label: "Jardiance - T2D" },
    { value: "Jardiance - CKD", label: "Jardiance - CKD" },
    { value: "Verzenio - eBC", label: "Verzenio - eBC" },
    { value: "Verzenio - mBC", label: "Verzenio - mBC" },
]
const sprintOptions = [
    { value: "", label: "Select Sprint" },
    { value: "ES3", label: "ES3" },
    { value: "ES4", label: "ES4" },
    { value: "AS4A", label: "AS4A" },
    { value: "AS4B", label: "AS4B" },
    { value: "AS4C", label: "AS4C" },
    { value: "ES5", label: "ES5" },
    { value: "AS5A", label: "AS5A" },
    { value: "AS5B", label: "AS5B" },
    { value: "AS5C", label: "AS5C" }
]
const brandOptions = [
    { value: "", label: "Select Brand" },
    { value: "All", label: "All"},
    { value: "Not Applicable", label: "Not Applicable" },
    { value: "Lebrikuzimab", label: "Lebrikuzimab" },
    { value: "Jardiance", label: "Jardiance" },
    { value: "Jaypirca", label: "Jaypirca" },
    { value: "Mounjaro", label: "Mounjaro" },
    { value: "Omvoh", label: "Omvoh" },
    { value: "Olumiant", label: "Olumiant" },
    { value: "Taltz", label: "Taltz" },
    { value: "Verzenio", label: "Verzenio" },
    { value: "Zepbound", label: "Zepbound" },
]
export const OrcaFormOptions = {
    "applicable_for_sleeve": salesForceOptions,
    "sales_force_team": salesForceOptions,
    "brand": brandOptions,
    "insight_graph_brand": brandOptions,
    "request_brand": [
        { value: "NA", label: "Not Applicable" },
        { value: "Lebrikuzimab", label: "Lebrikuzimab" },
        { value: "Jardiance", label: "Jardiance" },
        { value: "Jaypirca", label: "Jaypirca" },
        { value: "Mounjaro", label: "Mounjaro" },
        { value: "Omvoh", label: "Omvoh" },
        { value: "Olumiant", label: "Olumiant" },
        { value: "Taltz", label: "Taltz" },
        { value: "Verzenio", label: "Verzenio" },
        { value: "Zepbound", label: "Zepbound" },
    ],

	
    /*"indication": [
        { value: "", label: "Select Indication" },
        { value: "All", label: "All"},
        { value: "Not Applicable", label: "Not Applicable" },
        { value: "AA", label: "AA" },
        { value: "PsO", label: "PsO" },
        { value: "PsA", label: "PsA" },
        { value: "UC", label: "UC" },
        { value: "AD", label: "AD" },
        { value: "CLL", label: "CLL" },
        { value: "MCL", label: "MCL" },
        { value: "Anchor", label: "Anchor" },
        { value: "T2D", label: "T2D" },
        { value: "CWM", label: "CWM" },
        { value: "CKD", label: "CKD" },
        { value: "eBC", label: "eBC" },
        { value: "mBC", label: "mBC" },
    ],*/
    "brandIndicationMap": {
        '' : [ { value: "", label: "Select a brand first" } ],
		"olumiant": [ { value: "AA", label: "AA" } ],
		"taltz": [ { value: "PsO", label: "PsO" }, { value: "Psa", label: "PsA" } ],
		"omvoh": [ { value: "UC", label: "UC" } ],
		"lebrikuzimab": [ { value: "AD", label: "AD" } ],
		"jaypirca": [ { value: "CLL", label: "CLL" }, { value: "MCL", label: "MCL" }, { value: "Anchor", label: "Anchor" } ],
		"mounjaro": [ { value: "T2D", label: "T2D" } ],
		"zepbound": [ { value: "CWM", label: "CWM" } ],
		"jardiance": [ { value: "T2D", label: "T2D" }, { value: "CKD", label: "CKD" } ],
		"verzenio": [ { value: "eBC", label: "eBC" }, { value: "mBC", label: "mBC" } ],
    },
    "insight_category": [
        { value: "", label: "Select Insight Category" },
		{ value: "A - Predict # of prescription of specific drug class or specific product", label: "A - Predict # of prescription of specific drug class or specific product" },
		{ value: "B - Signal when Lilly product was newly prescribed", label: "B - Signal when Lilly product was newly prescribed" },
		{ value: "C - Signal when Lilly product prescription volume has decreased", label: "C - Signal when Lilly product prescription volume has decreased" },
		{ value: "D - Signal when prescription volume of specific drug class (Lilly product belongs to) has been decreasing", label: "D - Signal when prescription volume of specific drug class (Lilly product belongs to) has been decreasing" },
		{ value: "E - Signal when HCP received multiple samples but has not prescribed Lilly product yet", label: "E - Signal when HCP received multiple samples but has not prescribed Lilly product yet" },
		{ value: "F - Signal when HCP has switched to Lilly product from certain competitor product many times", label: "F - Signal when HCP has switched to Lilly product from certain competitor product many times" },
		{ value: "G - Signal when competitor product/class prescription is higher than Lilly product/class in recent period", label: "G - Signal when competitor product/class prescription is higher than Lilly product/class in recent period" },
		{ value: "H - Signal when competitor prescription is increasing in recent period", label: "H - Signal when competitor prescription is increasing in recent period" },
		{ value: "I - Signal when certain drug has been prescribed (such as ones that are not as good as Lilly product)", label: "I - Signal when certain drug has been prescribed (such as ones that are not as good as Lilly product)" },
		{ value: "J - Signal when HCP switched the drug to competitor product rather than Lilly product", label: "J - Signal when HCP switched the drug to competitor product rather than Lilly product" },
		{ value: "K - Signal when there was a switch from Lilly product to competitor product", label: "K - Signal when there was a switch from Lilly product to competitor product" },
		{ value: "L - Signal when there are patients on certain insurance coverage using competitor product (where Lilly should have advantage)", label: "L - Signal when there are patients on certain insurance coverage using competitor product (where Lilly should have advantage)" },
		{ value: "M - Signal when significant # of patients gained access to Lilly product", label: "M - Signal when significant # of patients gained access to Lilly product" },
		{ value: "N - Signal when there was insurance coverage rejection with Lilly product or with same drug class", label: "N - Signal when there was insurance coverage rejection with Lilly product or with same drug class" },
		{ value: "O - Signal when HCP has seen certain number of patients diagnosed with certain disease during recent period", label: "O - Signal when HCP has seen certain number of patients diagnosed with certain disease during recent period" },
		{ value: "P - Signal when there's a patient with certain lab test result which indicates opportunity for Lilly product", label: "P - Signal when there's a patient with certain lab test result which indicates opportunity for Lilly product" },
		{ value: "Q - Signal when HCP ordered a specific lab test", label: "Q - Signal when HCP ordered a specific lab test" },
		{ value: "R - Signal when current drug has had dose change", label: "R - Signal when current drug has had dose change" },
		{ value: "Others – Not on Playbook", label: "Others – Not on Playbook" },
    ],
    "business_unit": [
        { value: "", label: "Select Business Unit" },
        { value: "OBU", label: "OBU" },
        { value: "LDO", label: "LDO" },
        { value: "NS", label: "NS" },
        { value: "ILL", label: "IMM" },
        { value: "ALL", label: "All" }
    ],
    "requested_sprint": sprintOptions,
    "bv_category": [
        { value: "", label: "Select a category" },
        { value: "strategic_change", label: "Strategic Change / Business Needs" },
        { value: "field_experience", label: "Field Experience / Feedback" },
        { value: "backend_infra", label: "Backend Infra / Data pipeline Update" }
    ],
    "bv_priority": [
        { value: "", label: "Select a priority" },
        { value: "High", label: "High (Launch Brand - till 3m of launch)" },
        { value: "Med", label: "Medium (Tier 1)" },
        { value: "Low", label: "Low (Tiers 2 & 3)" }
    ],
    "bv_urgency": [
        { value: "", label: "Select urgency" },
        { value: "High", label: "High (Launch or Correcting Error)" },
        { value: "Med", label: "Medium (Enhancement/Feature Additions)" },
        { value: "Low", label: "Low (Cosmetic Changes)" }
    ],
    "bv_impact": [
        { value: "", label: "Select impact" },
        { value: "High", label: "High" },
        { value: "Medium", label: "Medium" },
        { value: "Low", label: "Low" }
    ],
    "refresh_freq": [
        { value: "", label: "Select Refresh Frequency"},
        { value: "Weekly", label: "Weekly" },
        { value: "Monthly", label: "Monthly" },
        { value: "Quarterly", label: "Quarterly" }
    ],
    "capability": [
        { value: "", label: "Select Capability" },
        { value: "portfolio_optimizer", label: "Portfolio Optimizer" },
        { value: "opportunity_model", label: "Opportunity Model" },
        { value: "dt_tool", label: "DT Tool" },
        { value: "br_insight", label: "Business Rule Insights" },
        { value: "cloudtag_insight", label: "Cloud Tag Insights" },
        { value: "portfolio_insights", label: "Portfolio Insights / Brand Summary Insights" },
    ],
    "br_text_type": [
        {value: "" ,label: "Select a text type"},
		{ value: "Static", label: "Static" },
		{ value: "Dynamic", label: "Dynamic" }
    ],
    "ta_priority": [
        {value: "" ,label: "Select Priority"},
        {value: "Very High",label: "Very High"},
        {value: "High",label: "High"},
        {value: "Medium",label: "Medium"},
        {value: "Low",label: "Low"},
        {value: "Very Low",label: "Very Low"}
    ],
    "br_insight_type": [
        {value: "" ,label: "Select an insight type"},
        { value: "New", label: "New" },
        { value: "Existing", label: "Existing" }
    ],
    "insight_graph_type": [
        {value: "" ,label: "Select a metric type"},
        { value: "New", label: "New" },
        { value: "Existing", label: "Existing" }
    ],
    "insight_graph_display_format": [
        {value: "" ,label: "Select a display format"},
        { value: "Single Line Chart", label: "Single Line Chart" },
        { value: "Multi Line Chart", label: "Multi Line Chart" },
        { value: "Pie Chart", label: "Pie Chart" },
        { value: "Bar Chart", label: "Bar Chart" },
        { value: "Static Numeric", label: "Static Numeric" },
        { value: "Static String", label: "Static String" },
    ],
    "insight_graph_display_section": [
        {value: "" ,label: "Select a display section"},
        { value: "HCP Profile", label: "HCP Profile" },
        { value: "HCP Insight", label: "HCP Insight" },
    ],
    "insight_graph_applicable_for_sleeve": salesForceOptions,
    "br_insight_graph": [
        { value: "", label: "Select if there is a visual metric" },
        { value: "No", label: "No" },
        { value: "Yes", label: "Yes" }
    ],
}
