/* eslint-disable no-async-promise-executor */
import Axios from "axios";
import { Configuration, PublicClientApplication } from "@azure/msal-browser";
import { msalConfig, loginRequest } from "../authConfig";

Axios.defaults.baseURL = "https://8r6l7pivtb-vpce-069388414a9f87f40.execute-api.us-east-2.amazonaws.com/dev/";
Axios.interceptors.response.use(null,(error) => {
  error.config.data = null;
  console.log("error.message", error);

  throw error;
});

const getToken = async () => {
  // get msal instance and account information from current context and ensure we have a valid token to access API
  // https://github.com/AzureAD/microsoft-authentication-library-for-js/issues/2927
  const msalInstance = new PublicClientApplication(msalConfig);
  await msalInstance.initialize();
  const activeAccount = msalInstance.getActiveAccount();

  // not entirely sure if this is possible - stale session from suspended machine?
  if (!activeAccount) {
    console.log("No active account, redirecting to login page");
    msalInstance.loginRedirect().catch((error) => console.log(error));
  }

  const response = await msalInstance.acquireTokenSilent({
    ...loginRequest,
    account: activeAccount,
  });

  return response.accessToken
};

export function OrcaAPI({ uri, method = "GET", headers = {"Content-Type": "application/json" }, body = null,}) {
  return new Promise(async (resolve, reject) => {
    const token = await getToken();
    if (headers["Authorization"] === undefined) {
      headers["Authorization"] = `Bearer ${token}`;
    }
  
    try {
      let response = null;
      const options = { headers };
      if (method.toLocaleLowerCase() === "post") {
        response = await Axios.post(uri, { ...body }, options);
      } else if (method.toLocaleLowerCase() === "put") {
        response = await Axios.put(uri, { ...body }, options);
      } else if (method.toLocaleLowerCase() === "delete") {
        response = await Axios.delete(uri, { ...body }, options);
      } else {
        response = await Axios.get(uri, options);
      }
      if (response && response.status === 200 && response.data && response.data.data) {
        resolve(response.data);
      } else {
        // Something else happened, log an error
        reject({
          status: "error",
          Message:
            "Something went wrong with API, Please try to refresh your browser !",
        });
      }
    // Catch a problem with the request itself
    } catch (err) {
      console.log("Error in OrcaAPI: ", err);
      reject({
        status: "error",
        Message:
          "Something went wrong with API, Please try to refresh your browser !",
      });
    }
  });
}
