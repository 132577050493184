import React from "react";
import Requests from "../Pages/Requests/Requests";
import NotFoundPage from "../Pages/SharedPages/NotFound";
import Contact from "../Pages/Contact/Contact";
import ServiceCatalog from "../Pages/ServiceCatalog/ServiceCatalog";
import SprintDates from "../Pages/SprintDates/SprintDates";
import { ConsolidatedSuggestionLibrary } from "../Pages/ConsolidateSuggestionLibrary/ConsolidatedSuggestionLibrary";
import { ConsolidatedInsightLibraryAdmin } from "../Pages/ConsolidatedInsightLibraryAdmin/ConsolidatedInsightLibraryAdmin";
import { MetricsLibraryAdmin } from "../Pages/MetricsLibraryAdmin/MetricsLibraryAdmin";
import MetricsLibrary from "../Pages/MetricsLibrary/MetricsLibrary";
import { BrandIndicationAdmin } from "../Pages/BrandIndicationAdmin/BrandIndicationAdmin";
import {SprintDateAdmin} from "../Pages/SprintDatesAdmin/SprintDatesAdmin";

export const protectedRoutes = [
    {
        path: "/requests",
        element: <Requests />,
        permissions: ["Viewer", "Requester", "Sprint Manager", "Service Cataloger", "Admin"]
    },
    {
        path: "/csl",
        element: <ConsolidatedSuggestionLibrary />,
        permissions: ["Viewer", "Requester", "Sprint Manager", "Service Cataloger", "Admin"]
    },
    {
        path: "/",
        element: <Requests />,
        permissions: ["Viewer", "Requester", "Sprint Manager", "Service Cataloger", "Admin"]
    },
    {
        path: "/servicecatalog",
        element: <ServiceCatalog />,
        permissions: ["Viewer", "Requester", "Sprint Manager", "Service Cataloger", "Admin"]
    },
    {
        path: "/sprintdates",
        element: <SprintDates />,
        permissions: ["Viewer", "Requester", "Sprint Manager", "Service Cataloger", "Admin"]
    },
    {
        path: "*",
        element: <NotFoundPage />,
        permissions: []
    },
    {
        path: "/contactus",
        element: <Contact />,
        permissions: ["Viewer", "Requester", "Sprint Manager", "Service Cataloger", "Admin"]
    },
    {
        path: "/cilAdmin",
        element: <ConsolidatedInsightLibraryAdmin />,
        permissions: ["Viewer", "Requester", "Sprint Manager", "Service Cataloger", "Admin"]
    },
    {
        path: "/ml",
        element: <MetricsLibrary />,
        permissions: ["Viewer", "Requester", "Sprint Manager", "Service Cataloger", "Admin"]
    },
    {
        path: "/brandindicationAdmin",
        element: <BrandIndicationAdmin />,
        permissions: ["Viewer", "Requester", "Sprint Manager", "Service Cataloger", "Admin"]
    },
    {
        path: "/metricslibraryAdmin",
        element: <MetricsLibraryAdmin />,
        permissions: ["Viewer", "Requester", "Sprint Manager", "Service Cataloger", "Admin"]
    },
    {
        path: "/SprintDateAdmin",
        element: <SprintDateAdmin />,
        permissions: ["Viewer", "Requester", "Sprint Manager", "Service Cataloger", "Admin"]
    }
            
]
