import React, { useState,useEffect } from 'react';
import { useFormikContext } from 'formik';
import { LdsButton, LdsScrollingCheckboxGroup, LdsValidationError } from '@elilillyco/ux-lds-react';
import { OrcaFormLabelOverrides, OrcaFormTooltipOverrides } from "./OrcaFormLabelOverrides";
import { OrcaFormOptions } from './OrcaFormOptions';
import { selectbrandindicationData, getLoadingState as getBrandLoadingState, getbrandindicationData } from "../../redux/slices/brndindSlice";
import { useSelector, useDispatch } from "react-redux";

export const OrcaScrollingCB = ({ handleChange, options, name, label, disabled, required, ...rest}) => {
    const [isAll, setIsAll] = useState(false);
    const { touched, errors, values, setFieldValue } = useFormikContext();
    let displayLabel = OrcaFormTooltipOverrides[name] || OrcaFormLabelOverrides[name] || label || '';
    if (required) {
        displayLabel = <>{displayLabel} <span title='Required' style={{cursor: 'help', color: 'red'}} className="required-label">*</span></>;
    }
    if (!values[name]) {
        values[name] = [];
        setFieldValue(name, []);
    }
    if (typeof values[name] == 'string' || values[name] instanceof String) {
        values[name] = values[name].split(';')
    }
    // eslint-disable-next-line no-unused-vars
    const isChecked = (value) => {
        return values[name].includes(value);
    };
    options = options || OrcaFormOptions[name] || [{ value: "", label: "" }];
    const scrollingCBOptions = options?.map((option) => { return { ...option, 'disabled':disabled }}) || []
    const setOptionValues = (newValues) => {
        touched[name] = true;
        setFieldValue(name, newValues);
    };
    return (<div style={{display: 'flex', flexDirection: 'column'}}>
        <LdsScrollingCheckboxGroup
        label={displayLabel}
        labelId={OrcaFormTooltipOverrides[name] || OrcaFormLabelOverrides[name] || label || ''}
        options={scrollingCBOptions}
        values={values[name]}
        required={rest?.required || false}
        error={errors[name] && touched[name]}
        setValues={setOptionValues}
        {...rest}
        />
        { errors[name] && touched[name] && <LdsValidationError id={"err-" + name}>{errors[name]}</LdsValidationError> }
    </div>);
};

export const OrcaBrandSelect = ({ name, formlink, ...rest }) => {
    const dispatch = useDispatch();
    const { values, setFieldValue } = useFormikContext();
    let [brandOptions, setBrandOptions] = React.useState([]);
    let BrandData = useSelector(selectbrandindicationData);
    let BrandLoadingState = useSelector(getBrandLoadingState);
    let request_brand = values[formlink]
    useEffect(() => {
        // Ensure BrandData is not null or undefined before filtering
        if (BrandData) {
            // Extract unique brand names
            const uniqueBrands = [...new Set(BrandData.map(option => option.brand))];
            const newBrandOptions = [
                { value: 'Not Applicable', label: 'Not Applicable' },
                ...uniqueBrands.map(brand => ({ value: brand, label: brand }))
            ];
            setBrandOptions(newBrandOptions);
        }
    }, [BrandData]);

    useEffect(() => {
        dispatch(getbrandindicationData());
    }, [dispatch]);


    return (
        <>
            <OrcaScrollingCB id={name} name={name} formlink={formlink} options={brandOptions} rows={BrandData} loading={BrandLoadingState} {...rest} />
        </>
    );
}
