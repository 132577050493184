import React,{useEffect} from 'react';
import { LdsButton} from "@elilillyco/ux-lds-react";
import { useSelector, useDispatch } from "react-redux";
import { getSprintData, selectSprintDatesData,getLoadingState } from '../../redux/slices/sprintDatesSlice'; 
import { OrcaTable } from '../../Components/common/OrcaTable';
import store from '../../redux/store';

const SprintDates = () => {
  const dispatch = useDispatch();
  const data = useSelector(selectSprintDatesData);
  const loading = useSelector(getLoadingState);

  useEffect(() => {
    dispatch(getSprintData());
  }, [dispatch]);

  return (
    <>
    <OrcaTable loading={loading}  rows={data} title="Sprint Dates" displayKey={{'sprint':{'display': true}, 'start_date':{'display': true}, 'mid_date':{'display':true}, 'end_date':{'display':true}}} />
    </>
  );
};

export default SprintDates;
